<template>
  <div>
    <slot :data="profissoes" :loading="loading"></slot>
  </div>
</template>

<script>
import catalogoService from '@/services/catalogoService';

export default {
  name: 'AsyncProfissoes',
  props: {
    cidade: {
      type: String,
      default: '',
    },
    estado: {
      type: String,
      default: '',
    },
    administradora: {
      type: Array,
    },
  },
  data() {
    return {
      loading: false,
      profissoes: [],
    };
  },
  methods: {
    async getData() {
      try {
        this.loading = true;
        const data = await catalogoService.getProfissoes({ cidade: this.cidade, uf: this.estado, administradora: this.administradora });
        this.profissoes = data;
        this.$emit('success', data);
      } catch (error) {
        this.$root.$snackBar.open({ color: 'error', message: error.message });
        this.$emit('error');
      } finally {
        this.loading = false;
      }
    },
  },
  async created() {
    if (this.cidade && this.estado) {
      await this.getData();
    }
  },
};
</script>

<style>
</style>
