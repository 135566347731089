<template>
  <div>
    <slot :data="supervisores" :loading="loading"></slot>
  </div>
</template>

<script>
import corretoraService from '@/services/corretoraService';

export default {
  name: 'AsyncSupervisores',
  props: {
    idCorretora: {
      default: '',
    },
    cpfCorretor: {
      default: '',
    },
  },
  data() {
    return {
      loading: false,
      supervisores: [],
    };
  },
  methods: {
    async getData() {
      try {
        this.loading = true;
        this.supervisores = await corretoraService.getSupervisoresCorretora(this.idCorretora, this.cpfCorretor);
        this.supervisores.unshift({
          nome: "Não informar supervisor",
          guid: "",
        });
        this.$emit('success', this.supervisores);
      } catch (error) {
        this.$root.$snackBar.open({ color: 'error', message: error.message });
        this.$emit('error');
      } finally {
        this.loading = false;
      }
    },
  },
  async created() {
    await this.getData();
  },
  watch: {
    idCorretora(newValue, oldValue) {
      if (newValue !== oldValue) this.getData();
    },
  },
};
</script>

<style>
</style>
