import api from '@/services/api';
import AppError from '@/utils/appError';

const resource = 'rede-referenciadas';

const getRedeReferenciada = async ({
  idProdutoFatura, UF,
}) => {
  try {
    const body = {
      idProdutoFatura,
      UF,
    };
    const config = { headers: { "X-Gravitee-Api-Key": process.env.VUE_APP_REDE_REFERENCIADA_API_KEY } };
    const { data } = await api.post(`${resource}/buscar`, body, config);
    return data;
  } catch (error) {
    const { response } = error;
    throw new AppError(response, 400);
  }
};
export default {
  getRedeReferenciada,
};
