module.exports = [
  { id: "AC", nome: "AC" },
  { id: "AL", nome: "AL" },
  { id: "AP", nome: "AP" },
  { id: "AM", nome: "AM" },
  { id: "BA", nome: "BA" },
  { id: "CE", nome: "CE" },
  { id: "DF", nome: "DF" },
  { id: "ES", nome: "ES" },
  { id: "GO", nome: "GO" },
  { id: "MA", nome: "MA" },
  { id: "MT", nome: "MT" },
  { id: "MS", nome: "MS" },
  { id: "MG", nome: "MG" },
  { id: "PA", nome: "PA" },
  { id: "PB", nome: "PB" },
  { id: "PR", nome: "PR" },
  { id: "PE", nome: "PE" },
  { id: "PI", nome: "PI" },
  { id: "RJ", nome: "RJ" },
  { id: "RN", nome: "RN" },
  { id: "RS", nome: "RS" },
  { id: "RO", nome: "RO" },
  { id: "RR", nome: "RR" },
  { id: "SC", nome: "SC" },
  { id: "SP", nome: "SP" },
  { id: "SE", nome: "SE" },
  { id: "TO", nome: "TO" },
];
